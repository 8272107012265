import {customElement, property, query} from "lit/decorators.js";
import {html, type TemplateResult} from "lit";
import Styles from "./textField.lit.scss";
import {InputElement} from "./inputElement";
import {InputElementType} from "../validations/validations";
import type {PropertyMap} from "../../../common/utils/objects";
import {directive} from "lit/directive.js";
import type {DirectiveResult} from "lit/directive";
import {SpreadAttributes} from "../../../common/directives/spreadAttributes";

@customElement("eop-text-field")
export class EopTextField extends InputElement<string> {

    public static readonly styles = Styles;

    @property({attribute: "label"})
    public label: string;
    @query("input")
    private input: HTMLInputElement;

    public constructor(private spreadAttributes: (attributes: PropertyMap<string>) => DirectiveResult = directive(SpreadAttributes)) {
        super("");
        this.type = InputElementType.TEXT;
    }

    public render(): TemplateResult {
        return html`
            <div class="input-element ${this.basicClassMap()}">
                <input
                        id=${this.formUUId}
                        name=${this.getFullId()}
                        .value=${this.value}
                        ?filled=${this.value}
                        @change=${this.change}
                        @input=${this.onInput}
                        type=${this.type}
                        ?required=${this.required}
                        ${this.spreadAttributes(this.customAttributes)}
                >
                <label for=${this.formUUId} @click=${this.preventLabelDefaultAction}>
                    <span class="label-text">${this.label}</span>
                    ${this.labelSuffix()}
                </label>
                ${this.renderValidationIcon()}
            </div>
            ${(this.renderValidationMessages())}
        `;
    }

    public focusInput(): void {
        this.input.focus();
    }

    protected preset(): string | undefined {
        return this.getAttribute("preset") ?? undefined;
    }

    private change(event: Event): void {
        this.updateValue((event.target as HTMLInputElement).value);
    }

    private onInput(event: Event): void {
        if (this.isValidatable) {
            this.updateValidState((event.target as HTMLInputElement).value);
        }
    }
}