import {autoRegisterAs, resolve} from "../../container";
import {promiseFromJsonResponse} from "../../common/utils/promises";
import {SecuredRequestExecutor} from "../../module/awswaf/securedRequestExecutor";
import {RequestSugarFactory} from "../common/requestSugar";
import {Tracking} from "../../tracking/tracking";

import {TrackingEventName} from "../../tracking/types";

export type SubmitResponse = {
    success: boolean;
    errorId?: string;
};

export type SubmitRequest = {
    lang: string;
    formId: string;
    configId: string;
    formData: any;
};

export interface SubmitService {
    submit: (request: SubmitRequest) => Promise<SubmitResponse>;
}

@autoRegisterAs("formService")
export class FormService implements SubmitService {

    public constructor(
        private securedRequestExecutor: SecuredRequestExecutor = resolve(SecuredRequestExecutor),
        private tracking: Tracking = resolve(Tracking)
    ) {
    }

    public async submit(request: SubmitRequest): Promise<SubmitResponse> {
        const base = "/enbw-eop/eop-app-form/rest/stable/submits/submitForm";
        const postRequest = (): Promise<Response> => fetch(`${base}/${request.configId}/${request.lang}`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                    "EOP-Sugar": RequestSugarFactory.produce()
                },
                body: JSON.stringify(request.formData)
            });

        const response = await this.securedRequestExecutor.execute(postRequest);
        if (response.ok) {
            this.tracking.emitFormTrackingEvent({
                name: TrackingEventName.FORM_SUCCESS,
                data: {
                    name: request.configId
                }
            });
        }
        return promiseFromJsonResponse(response);
    }
}